import { Switch, Route } from "react-router-dom";
import Dashboard from "../../pages/dashboard/Dashboard";
import E404 from "../../pages/errors/E404";
import RouteSetting from "./routes";

// Store Settings
import StoreForm from "../../pages/store/StoreForm";

// Accounts
import Accounts from "../../pages/account/Accounts";
import AccountForm from "../../pages/account/AccountForm";

// Business Accounts
import BusinessAccounts from "../../pages/business_account/Accounts";
import BusinessAccountForm from "../../pages/business_account/AccountForm";

// Apps
import Apps from "../../pages/app/Apps";
import AppForm from "../../pages/app/AppForm";

// Order
import Orders from "../../pages/order/Orders";
import OrdersAbandoned from "../../pages/order/OrdersAbandoned";
import OrderForm from "../../pages/order/OrderForm";
import OrderFulfillment from "../../pages/order/OrderFulfillment";

// Product
import Products from "../../pages/product/Products";

// Category
import Categories from "../../pages/category/Categories";
import CategoryForm from "../../pages/category/CategoryForm";

// Brand
import Brands from "../../pages/brand/Brands";
import BrandForm from "../../pages/brand/BrandForm";

// Vendor
import Vendors from "../../pages/vendor/Vendors";

// Page
import Pages from "../../pages/page/Pages";
import PageForm from "../../pages/page/PageForm";

// Landing
import Landings from "../../pages/landing/Landings";
import LandingBuilder from "../../pages/landing/LandingBuilder";

// Navigation
import Navigations from "../../pages/navigation/Navigations";
import NavigationForm from "../../pages/navigation/NavigationForm";

// Promotion
import Promotions from "../../pages/promotion/Promotions";
import PromotionForm from "../../pages/promotion/PromotionForm";

// Customer
import Customers from "../../pages/customer/Customers";
import CustomerForm from "../../pages/customer/CustomerForm";

// Logistics
import OnfleetDrivers from "../../pages/logistics/OnfleetDrivers";
import TreezGroups from "pages/pos/TreezGroups";
import TreezDiscounts from "pages/pos/TreezDiscounts";

// Blaze Inventory
import BlazeInventory from "../../pages/blaze_inventory/BlazeInventory";
import Treez from "pages/pos/Treez";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Dashboard />
            </Route>

            {/* Store Settings */}
            <Route path={`${RouteSetting.SETTINGS.route}`}>
                <StoreForm />
            </Route>

            {/* Accounts */}
            <Route path={`${RouteSetting.ACCOUNTS.route}`}>
                <Accounts />
            </Route>
            <Route path={`${RouteSetting.ACCOUNT_CREATE.route}`}>
                <AccountForm />
            </Route>
            <Route path={`${RouteSetting.ACCOUNT_DETAIL.route}`}>
                <AccountForm />
            </Route>

            {/* Business Account */}
            <Route path={`${RouteSetting.BUSINESS_ACCOUNTS.route}`}>
                <BusinessAccounts />
            </Route>
            <Route path={`${RouteSetting.BUSINESS_ACCOUNT_CREATE.route}`}>
                <BusinessAccountForm />
            </Route>
            <Route path={`${RouteSetting.BUSINESS_ACCOUNT_DETAIL.route}`}>
                <BusinessAccountForm />
            </Route>

            {/* Apps */}
            <Route path={`${RouteSetting.APP_LIST.route}`}>
                <Apps />
            </Route>
            <Route path={`${RouteSetting.APP_CREATE.route}`}>
                <AppForm />
            </Route>
            <Route path={`${RouteSetting.APP_DETAIL.route}`}>
                <AppForm />
            </Route>

            {/* Orders */}
            <Route path={`${RouteSetting.ORDER_LIST.route}`}>
                <Orders />
            </Route>
            <Route path={`${RouteSetting.ORDER_ABANDONED.route}`}>
                <OrdersAbandoned />
            </Route>
            <Route path={`${RouteSetting.ORDER_CREATE.route}`}>
                <OrderForm />
            </Route>
            <Route path={`${RouteSetting.ORDER_DETAIL.route}`}>
                <OrderForm />
            </Route>
            <Route path={`${RouteSetting.ORDER_FULFILMENT.route}`}>
                <OrderFulfillment />
            </Route>

            {/* Product */}
            <Route path={`${RouteSetting.PRODUCT_LIST.route}`}>
                <Products />
            </Route>

            <Route path={`${RouteSetting.PRODUCT_BLAZE_INVENTORY.route}`}>
                <BlazeInventory />
            </Route>

            {/* Brand */}
            <Route path={`${RouteSetting.BRAND_LIST.route}`}>
                <Brands />
            </Route>
            <Route path={`${RouteSetting.BRAND_DETAIL.route}`}>
                <BrandForm />
            </Route>

            {/* Vendor */}
            <Route path={`${RouteSetting.VENDOR_LIST.route}`}>
                <Vendors />
            </Route>

            {/* Category */}
            <Route path={`${RouteSetting.CATEGORY_LIST.route}`}>
                <Categories />
            </Route>
            <Route path={`${RouteSetting.CATEGORY_DETAIL.route}`}>
                <CategoryForm />
            </Route>

            {/* Page */}
            <Route path={`${RouteSetting.PAGE_LIST.route}`}>
                <Pages />
            </Route>
            <Route path={`${RouteSetting.PAGE_CREATE.route}`}>
                <PageForm />
            </Route>
            <Route path={`${RouteSetting.PAGE_DETAIL.route}`}>
                <PageForm />
            </Route>

            {/* Landing */}
            <Route path={`${RouteSetting.LANDING_LIST.route}`}>
                <Landings />
            </Route>
            <Route path={`${RouteSetting.LANDING_CREATE.route}`}>
                <LandingBuilder />
            </Route>
            <Route path={`${RouteSetting.LANDING_DETAIL.route}`}>
                <LandingBuilder />
            </Route>

            {/* Navigation */}
            <Route path={`${RouteSetting.NAVIGATION_LIST.route}`}>
                <Navigations />
            </Route>
            <Route path={`${RouteSetting.NAVIGATION_CREATE.route}`}>
                <NavigationForm />
            </Route>
            <Route path={`${RouteSetting.NAVIGATION_DETAIL.route}`}>
                <NavigationForm />
            </Route>

            {/* Promotion */}
            {/* <Route path={`${RouteSetting.PROMOTION_LIST.route}`}>
                <Promotions />
            </Route>
            <Route path={`${RouteSetting.PROMOTION_CREATE.route}`}>
                <PromotionForm />
            </Route>
            <Route path={`${RouteSetting.PROMOTION_DETAIL.route}`}>
                <PromotionForm />
            </Route> */}

            {/* Customer */}
            <Route path={`${RouteSetting.CUSTOMER_LIST.route}`}>
                <Customers />
            </Route>
            <Route path={`${RouteSetting.CUSTOMER_DETAIL.route}`}>
                <CustomerForm />
            </Route>

            {/* Treez */}
            <Route path={RouteSetting.TREEZ_PRODUCTS.route}>
                <Treez />
            </Route>
            <Route path={RouteSetting.TREEZ_LIST_GROUPS.route}>
                <TreezGroups />
            </Route>
            <Route path={RouteSetting.TREEZ_LIST_DISCOUTS.route}>
                <TreezDiscounts />
            </Route>

            {/* Logistics */}
            <Route path={`${RouteSetting.ONFLEET_LIST.route}`}>
                <OnfleetDrivers />
            </Route>

            <Route>
                <E404 />
            </Route>
        </Switch>
    );
};

export default Routes;
