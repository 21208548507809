import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";

const AeropayCredentialForm = ({
    isCreate,
    handleCancel,
    app,
    stores,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "aeropay_store_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store provider id is required.",
            },
            {
                field: "aeropay_host",
                method: "isEmpty",
                validWhen: false,
                message: "This Host is required.",
            },
            {
                field: "aeropay_api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Api key is required.",
            },
            {
                field: "aeropay_api_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Api secret is required.",
            },
            {
                field: "aeropay_merchant_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Merchant id is required.",
            },
            {
                field: "aeropay_location_uuid",
                method: "isEmpty",
                validWhen: false,
                message: "ThisLocation UUID is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                aeropay_sandbox: objSelect?.aeropay_sandbox,
                aeropay_host: objSelect?.aeropay_host,
                aeropay_api_key: objSelect?.aeropay_api_key,
                aeropay_api_secret: objSelect?.aeropay_api_secret,
                aeropay_merchant_id: objSelect?.aeropay_merchant_id,
                aeropay_store_provider_id: objSelect?.aeropay_store_provider_id,
                aeropay_location_uuid: objSelect?.aeropay_location_uuid,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials ?? []);

            if (isCreate) {
                accounts.push({
                    aeropay_sandbox: inputs?.aeropay_sandbox,
                    aeropay_host: inputs?.aeropay_host,
                    aeropay_api_key: inputs?.aeropay_api_key,
                    aeropay_api_secret: inputs?.aeropay_api_secret,
                    aeropay_merchant_id: inputs?.aeropay_merchant_id,
                    aeropay_location_uuid: inputs?.aeropay_location_uuid,
                    aeropay_store_provider_id:
                        inputs?.aeropay_store_provider_id,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="p-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Credential Informations</SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={4} md={6}>
                        <FormGroup row>
                            <Label className="row-label mr-3 pl-0">
                                Sandbox
                            </Label>

                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.aeropay_sandbox
                                                ? "checked"
                                                : ""
                                        }
                                        name="aeropay_sandbox"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Provider Id</Label>
                            <Select
                                controlName="aeropay_store_provider_id"
                                invalid={
                                    validator.aeropay_store_provider_id
                                        .isInvalid
                                }
                                onChange={selectChange}
                                options={stores}
                                value={selectValue(
                                    inputs.aeropay_store_provider_id,
                                    stores,
                                )}
                            />
                            <FormFeedback>
                                {validator.aeropay_store_provider_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api key</Label>
                            <Input
                                invalid={validator.aeropay_api_key.isInvalid}
                                name="aeropay_api_key"
                                onChange={inputChange}
                                placeholder="Api key"
                                value={inputs.aeropay_api_key}
                            />
                            <FormFeedback>
                                {validator.aeropay_api_key.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api secret</Label>
                            <Input
                                invalid={validator.aeropay_api_secret.isInvalid}
                                name="aeropay_api_secret"
                                onChange={inputChange}
                                placeholder="Api secret"
                                value={inputs.aeropay_api_secret}
                            />
                            <FormFeedback>
                                {validator.aeropay_api_secret.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Merchant id</Label>
                            <Input
                                invalid={
                                    validator.aeropay_merchant_id.isInvalid
                                }
                                name="aeropay_merchant_id"
                                onChange={inputChange}
                                placeholder="Merchant id"
                                value={inputs.aeropay_merchant_id}
                            />
                            <FormFeedback>
                                {validator.aeropay_merchant_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Host</Label>
                            <Input
                                invalid={validator.aeropay_host.isInvalid}
                                name="aeropay_host"
                                onChange={inputChange}
                                placeholder="Host"
                                value={inputs.aeropay_host}
                            />
                            <FormFeedback>
                                {validator.aeropay_host.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Location UUID</Label>
                            <Input
                                invalid={
                                    validator.aeropay_location_uuid.isInvalid
                                }
                                name="aeropay_location_uuid"
                                onChange={inputChange}
                                placeholder="Location uid"
                                value={inputs.aeropay_location_uuid}
                            />
                            <FormFeedback>
                                {validator.aeropay_location_uuid.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default AeropayCredentialForm;
