import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Button } from "reactstrap";
import useNotification from "../../../hooks/components/useNotification";
import { QUERY_CHECK_DEPLOYMENT_STATUS } from "../../../../apollo/module-operations/deployment";
import { Icon, Spinner } from "@/components";
import { useMeta } from "@/hooks";
import { useStoreContext } from "context";
import classNames from "classnames";
import { useLazyQuery } from "@apollo/react-hooks";

const DeploymentStatus = () => {
    //const { formatDistanceToNowStrict, parseISO } = require("date-fns");
    const initialState = {
        statusName: "READY",
        lastUpdate: "",
        icon: "status-success",
    };
    const { store } = useStoreContext();
    const { user } = useMeta();
    const notify = useNotification();

    const [open, isOpen] = useState(false);
    const [buildingStatus, setBuildingStatus] = useState(false);
    const [purgeLoading, setPurgeLoading] = useState(false);
    const [status, setStatus] = useState(initialState);
    const statusErrors = ["ERROR"];
    const statusBuilding = ["BUILDING", "INITIALIZING", "QUEUED"];

    const activeLoading = buildingStatus || loadingStatus || purgeLoading;

    const toggle = () => {
        isOpen(open => !open);
    };

    const [
        checkDeploymentStatus,
        { loading: loadingStatus, networkStatus, refetch, fetchMore },
    ] = useLazyQuery(QUERY_CHECK_DEPLOYMENT_STATUS, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            const data = resp?.checkDeploymentStatus;
            if (statusBuilding.includes(data.status)) {
                monitorDeployment(true);
                return;
            }

            if (data?.status !== status?.statusName) {
                getFormatStatus(data);
            }
        },
        onError: resp => {
            setStatus({
                statusName: "Error",
                lastUpdate: "",
                icon: getStatusIcon("Error"),
            });
            notify(resp?.message, "error");
        },
    });

    const handleRunDeployment = () => {
        fetch(store?.provider_deployment_url)
            .then(response => {
                if (!response.ok) {
                    notify(
                        "An error occurs while running the deployment, please try again later",
                        "error",
                    );
                }
                return response.json();
            })
            .catch(error => {
                notify(error.message, "error");
            });
    };

    const handlePurgeCache = async () => {
        const url = `${store.domain}/api/revalidate`;
        if (store.domain) {
            await fetch(url, {
                method: "POST",
            })
                .then(response => {
                    if (!response.ok) {
                        notify(
                            "An error occurs while running the deployment, please try again later",
                            "error",
                        );
                        return;
                    }
                    setPurgeLoading(false);
                    notify("The cache was updated successfully", "success");
                    return response.json();
                })
                .catch(error => {
                    setPurgeLoading(false);
                    notify(error.message, "error");
                });
        } else {
            setPurgeLoading(false);
            notify("Please configure the store domain", "warning");
        }
    };

    const monitorDeployment = async deployment => {
        try {
            isOpen(false);
            setBuildingStatus(true);
            setStatus({
                statusName: "BUILDING",
                lastUpdate: "",
                icon: "status-building",
            });
            if (!deployment) {
                handleRunDeployment();
            }
            const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

            let checkStatus = "BUILDING";
            while (checkStatus === "BUILDING") {
                await delay(90000); // Wait 1 minute and 30 seconds

                const check = await refetch();

                if (check) {
                    const value = check?.data?.checkDeploymentStatus?.status;
                    if (statusBuilding.includes(value)) {
                        checkStatus = "BUILDING";
                    } else {
                        checkStatus =
                            check?.data?.checkDeploymentStatus?.status;
                    }
                }
            }

            setBuildingStatus(false);
            if (checkStatus !== "ERROR") {
                notify(
                    `Deployment completed with status: ${checkStatus}`,
                    "success",
                );

                return;
            }
            notify(`Failed deployment`, "error");
        } catch (error) {
            console.error("Error en el proceso de deployment:", error);
        }
    };

    const getStatusIcon = status => {
        if (status === "READY") {
            return "status-success";
        }

        if (statusErrors.includes(status)) {
            return "status-error";
        }

        return "status-building";
    };

    const getStatusName = () => {
        if (status.statusName === "READY") {
            return "Online";
        }

        if (status.statusName === "ERROR") {
            return "Error";
        }

        return "Building";
    };

    const getFormatStatus = data => {
        if (!data.status) {
            setStatus(initialState);
            return;
        }

        if (data.status === "READY" || data.status === "CANCELED") {
            setStatus({
                statusName: "READY",
                icon: "status-success",
                time: "",
            });
            return;
        }

        if (statusErrors.includes(data.status)) {
            setStatus({
                statusName: "ERROR",
                icon: "status-error",
                time: "",
            });
            return;
        }

        setStatus({
            statusName: "BUILDING",
            icon: "status-building",
            time: "",
        });
        return;
    };

    useEffect(() => {
        !user.loading && checkDeploymentStatus();

        // eslint-disable-next-line
    }, [user.loading, store]);

    return (
        <div className="k-header__topbar-item k-header__topbar-item--user">
            <Dropdown
                className="k-header__topbar-wrapper k-header__topbar-wrapper--deployment-status"
                isOpen={open}
                toggle={toggle}
            >
                <DropdownToggle
                    className="k-header__topbar-user k-header__topbar-user-store"
                    role="button"
                    tag="div"
                >
                    <span className="k-header__topbar-icon k-header__topbar-icon-store">
                        {!loadingStatus && !buildingStatus && !purgeLoading ? (
                            <Icon icon={status.icon || "status-error"} />
                        ) : (
                            <Spinner
                                color={buildingStatus ? "secondary" : "metalic"}
                                show
                                sm
                            />
                        )}
                    </span>
                    <div className="k-header__topbar-welcome-store">
                        <span className="k-header__topbar-tag">
                            Status store
                        </span>
                        {((!loadingStatus && !purgeLoading) ||
                            buildingStatus) && (
                            <span
                                className={classNames(
                                    "k-header__topbar-tag k-header__topbar-tag-store tag__status",
                                    {
                                        ["tag__status-building"]:
                                            status.statusName === "BUILDING",
                                        ["tag__status-error"]:
                                            status.statusName === "ERROR",
                                    },
                                )}
                            >
                                {getStatusName()}
                            </span>
                        )}

                        {(loadingStatus || purgeLoading) && !buildingStatus && (
                            <span
                                className={classNames(
                                    "k-header__topbar-tag k-header__topbar-tag-store",
                                )}
                            >
                                Loading
                            </span>
                        )}
                    </div>
                    <i className="k-menu__ver-arrow k-menu__ver-arrow-store fas fa-chevron-down" />
                </DropdownToggle>
                <DropdownMenu center>
                    <Button
                        color="primary"
                        disabled={
                            !store?.provider_deployment_url || activeLoading
                        }
                        key="runDeployment"
                        onClick={() => monitorDeployment(false)}
                    >
                        <Icon icon={"push"} />
                        <span>Publish</span>
                    </Button>
                    <Button
                        color="secondary"
                        disabled={activeLoading}
                        key="purg"
                        onClick={() => {
                            setPurgeLoading(true);
                            handlePurgeCache();
                            isOpen();
                        }}
                    >
                        <Icon icon={"purge-cache"} />
                        <span>Purge Cache</span>
                    </Button>
                    <Button
                        color="secondary"
                        disabled={activeLoading}
                        key="syncStatus"
                        onClick={() => {
                            refetch();
                            isOpen();
                        }}
                    >
                        <Icon icon={"refresh"} />
                        <span>Check Status</span>
                    </Button>
                    {/*  <div className="status-last-update">
                        <span>Last Update:</span>
                        <span className="last-update--time">
                            {status?.lastUpdate}
                        </span>
                    </div> */}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default DeploymentStatus;
