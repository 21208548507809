import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import classNames from "classnames";
import logo from "../../../assets/images/logo.svg";
import { QUERY_UI } from "../../../apollo/queries/local";
import { MUTATION_UI } from "../../../apollo/mutations/local";

const Brand = () => {
    const { data } = useQuery(QUERY_UI);

    const { asideHover, asideMinimize } = data.ui;

    const minimized = asideHover || asideMinimize;

    const [updateUi] = useMutation(MUTATION_UI, {
        variables: {
            ui: {
                asideHover: false,
                asideMinimize: !minimized,
                asideToggle: false,
            },
        },
    });

    return (
        <div className="k-aside__brand k-grid__item">
            <div className="k-aside__brand-logo">
                <Link to="/">
                    <img alt="TREEZ" src={logo} width={"160px"} />
                </Link>
            </div>
            <div className="k-aside__brand-tools">
                <button
                    className={classNames(
                        "k-aside__brand-aside-toggler",
                        "k-aside__brand-aside-toggler--left",
                        {
                            "k-aside__brand-aside-toggler--active": minimized,
                        },
                    )}
                    onClick={updateUi}
                >
                    <span />
                </button>
            </div>
        </div>
    );
};

export default Brand;
