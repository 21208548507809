import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Badge, Portlet, Section, SectionTitle, Table } from "@/components";
import { Button, Col, Row } from "reactstrap";
import Form from "../../base/components/form/Form";
import {
    useFormActions,
    useForm,
    useMeta,
    useNotification,
    useConfirm,
} from "@/hooks";
import {
    MUTATION_UPDATE_AEROPAY,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import omit from "lodash/omit";
import { useMutation } from "@apollo/react-hooks";
import AeropayCredentialForm from "./AeropayCredentialForm";

const AeropayForm = ({ app, loading, stores }) => {
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [credentials, setCredentials] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const notify = useNotification();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_AEROPAY, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, credentials });
            notify(
                `Credential Aeropay Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Credential, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const formatCredentials = credentials => {
        if (!credentials) {
            return [];
        }
        return credentials?.map(c => ({
            sandbox: c?.aeropay_sandbox,
            host: c.aeropay_host,
            api_key: c.aeropay_api_key,
            api_secret: c.aeropay_api_secret,
            merchant_id: c.aeropay_merchant_id,
            location_uuid: c.aeropay_location_uuid,
            store_provider_id: c.aeropay_store_provider_id,
        }));
    };

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...omit(
                    {
                        ...inputs,
                        id: inputs.id,
                        host: inputs.host_setting,
                        api_key: inputs.api_key_setting,
                        credentials: formatCredentials(inputs?.credentials),
                        type: "Aeropay",
                    },
                    ["api_key_setting", "host_setting"],
                ),
            },
        },
    };

    const [confirm, launch] = useConfirm(() => {
        mutate(options);
    }, `Are You Sure You Want To Remove The Credential ?`);

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Aeropay",
        "",
        MUTATION_UPDATE_AEROPAY,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        () => {
            return true;
        },
        () => {
            return true;
        },
        "primary",
    );

    const columns = [
        {
            dataField: "aeropay_store_provider_id",
            text: "Store Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_store_provider_id || ""}`}</div>;
            },
            sort: true,
        },
        {
            dataField: "aeropay_sandbox",
            text: "Sandbox",
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.aeropay_sandbox
                                ? "status__success"
                                : "status__metalic"
                        }
                        label={row.aeropay_sandbox ? "Yes" : "No"}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "aeropay_host",
            text: "Host",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_host || ""}`}</div>;
            },
        },
        {
            dataField: "aeropay_api_key",
            text: "Api key",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_api_key || ""}`}</div>;
            },
        },
        {
            dataField: "aeropay_api_secret",
            text: "Api Secret",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_api_secret || ""}`}</div>;
            },
        },
        {
            dataField: "aeropay_merchant_id",
            text: "Merchant Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_merchant_id || ""}`}</div>;
            },
        },
        {
            dataField: "aeropay_location_uuid",
            text: "Location UUID",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.aeropay_location_uuid || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setCredentials(
                    _.filter(
                        inputs.credentials,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...omit(
                        {
                            ...inputs,
                            id: inputs.id,
                            host: inputs.host_setting,
                            api_key: inputs.api_key_setting,
                            api_secret: inputs.api_secret,
                            merchant_id: inputs.merchant_id,
                            credentials: formatCredentials(array),
                            type: "Aeropay",
                        },
                        ["api_key_setting", "host_setting"],
                    ),
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const _credentials = (inputs && inputs.credentials) || [];

    return (
        <>
            <Portlet
                centered
                className="business__settings"
                header={{
                    title: "Aeropay",
                    subtitle: "Aeropay Information",
                }}
                sticky
                toolbar={[
                    <Button
                        className="ml-3"
                        color="secondary"
                        key="AddCredential"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Credential
                    </Button>,
                    createOrUpdateButton,
                ]}
            >
                {!user.loading ? (
                    <Form>
                        <Section>
                            {showForm && (
                                <AeropayCredentialForm
                                    app={inputs}
                                    credentials={credentials}
                                    handleCancel={handleCancel}
                                    handlerUpdatingOrCreate={
                                        handlerUpdatingOrCreate
                                    }
                                    isCreate={isCreate}
                                    isUpdating={isUpdating}
                                    selectCredential={rowSelect}
                                    setCredentials={setCredentials}
                                    stores={stores}
                                />
                            )}
                            {/* TODO: note sure if this is the best way to create an space here*/}
                            <br></br>
                            <Row>
                                <Col>
                                    <SectionTitle md>Credentials</SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        actions={actions}
                                        columns={columns}
                                        data={_credentials}
                                        keyField="store_id"
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Section>
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default AeropayForm;
