import React from "react";
import { Section } from "../../base/components/portlet";
import { Col, Row, Button } from "reactstrap";
import { Form, Input, SectionTitle, Spinner } from "@/components";

const SchedulesFormJSON = ({
    context,
    handleCancel,
    handlerUpdatingOrCreate,
    inputs,
    setInputs,
    isUpdating,
}) => {
    const getTitle = () => {
        let titleContext = "Delivery Schedules";

        if (context === "Delivery") {
            if (inputs?.delivery_schedules) {
                return `Update ${titleContext}`;
            }

            return `Add ${titleContext}`;
        }

        if (context === "Closures") {
            titleContext = "Closures";

            if (inputs?.closures) {
                return `Update ${titleContext}`;
            }

            return `Add ${titleContext}`;
        }

        titleContext = "Pickup Schedules";

        if (inputs?.pickup_schedules) {
            return `Update ${titleContext}`;
        }

        return `Add ${titleContext}`;
    };

    const handleChange = e => {
        const value = e.target.value;

        let jsonObject = JSON.parse("[]");

        if (value) {
            jsonObject = JSON.parse(value);
        }

        if (context === "Delivery") {
            setInputs({ ...inputs, delivery_schedules: jsonObject });
            return;
        }

        if (context === "Closures") {
            setInputs({ ...inputs, closures: jsonObject });
            return;
        }

        setInputs({ ...inputs, pickup_schedules: jsonObject });
        return;
    };

    const getValue = () => {
        let data = inputs.pickup_schedules;
        if (context === "Delivery") {
            data = inputs.delivery_schedules;
        }

        if (context === "Closures") {
            data = inputs.closures;
        }

        if (data?.length > 0) {
            return JSON.stringify(data, null, 2);
        }

        return [];
    };

    return (
        <Form className="mb-4">
            <Section className="p-4 mb-2">
                <Row>
                    <Col>
                        <SectionTitle md>{getTitle()}</SectionTitle>
                    </Col>
                </Row>
            </Section>
            <Section>
                <Row className="px-2">
                    <Col>
                        <Input
                            name="schedule"
                            onChange={e => handleChange(e)}
                            placeholder="Write the schedule..."
                            rows={30}
                            type="textarea"
                            value={getValue()}
                        />
                    </Col>
                </Row>
            </Section>
            <Section className="p-4 mb-2">
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={() => {
                            handlerUpdatingOrCreate(inputs);
                        }}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
            <Section className="section-separator" />
        </Form>
    );
};

export default SchedulesFormJSON;
