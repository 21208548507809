import routes from "../routes/routes";

const {
    DASHBOARD,
    ORDER_LIST,
    ORDER_ABANDONED,
    PRODUCT_LIST,
    BRAND_LIST,
    CATEGORY_LIST,
    VENDOR_LIST,
    PAGE_LIST,
    PAGE_CREATE,
    PRODUCT_BLAZE_INVENTORY,
    NAVIGATION_LIST,
    NAVIGATION_CREATE,
    PROMOTION_LIST,
    PROMOTION_CREATE,
    CUSTOMER_LIST,
    ONFLEET_LIST,
    TREEZ_LIST_PRODUCTS,
    TREEZ_LIST_GROUPS,
    TREEZ_LIST_DISCOUTS,
    CUSTOMER_CREATE,
    SETTINGS,
    TREEZ_PRODUCTS,
} = routes;

export const navigation = [
    {
        label: DASHBOARD.label,
        route: DASHBOARD.route,
        icon: "dashboard",
    },
    {
        label: "Your Orders",
    },
    {
        label: "Orders",
        icon: "shopping-cart",
        items: [
            {
                label: ORDER_LIST.label,
                route: ORDER_LIST.route,
            },
            /* {
                label: ORDER_ABANDONED.label,
                route: ORDER_ABANDONED.route,
            }, */
        ],
    },
    /* {
        label: "Catalog",
    },
    {
        label: "Products",
        icon: "fas fa-box-open",
        items: [
            {
                label: PRODUCT_LIST.label,
                route: PRODUCT_LIST.route,
            },
            {
                label: PRODUCT_BLAZE_INVENTORY.label,
                route: PRODUCT_BLAZE_INVENTORY.route,
            },
        ],
    },
     {
        label: "Categories",
        icon: "fas fa-folder",
        items: [
            {
                label: CATEGORY_LIST.label,
                route: CATEGORY_LIST.route,
            },
        ],
    },

    {
        label: "Brands",
        icon: "fas fa-folder",
        items: [
            {
                label: BRAND_LIST.label,
                route: BRAND_LIST.route,
            },
        ],
    },

    {
        label: "Vendors",
        icon: "fas fa-folder",
        items: [
            {
                label: VENDOR_LIST.label,
                route: VENDOR_LIST.route,
            },
        ],
    },

    {
        label: "Content",
    },
    {
        label: "Pages",
        icon: "fas fa-copy",
        items: [
            {
                label: PAGE_LIST.label,
                route: PAGE_LIST.route,
            },
            {
                label: PAGE_CREATE.label,
                route: PAGE_CREATE.route,
            },
        ],
    },
    {
        label: "Navigation",
        icon: "fas fa-link",
        items: [
            {
                label: NAVIGATION_LIST.label,
                route: NAVIGATION_LIST.route,
            },
            {
                label: NAVIGATION_CREATE.label,
                route: NAVIGATION_CREATE.route,
            },
        ],
    }, */
    {
        label: "Your Customers",
    },
    {
        label: "Customers",
        icon: "costumers",
        items: [
            {
                label: CUSTOMER_LIST.label,
                route: CUSTOMER_LIST.route,
            },
        ],
    },
    /*   {
        label: "Promotion",
    },
    {
        label: "Promotion",
        icon: "promotion",
        items: [
            {
                label: PROMOTION_LIST.label,
                route: PROMOTION_LIST.route,
            },
            {
                label: PROMOTION_CREATE.label,
                route: PROMOTION_CREATE.route,
            },
        ],
    }, */
    {
        label: "Apps",
    },
    {
        label: "Logistics",
        icon: "car",
        items: [
            {
                label: ONFLEET_LIST.label,
                route: ONFLEET_LIST.route,
                handler: ONFLEET_LIST.handler,
            },
        ],
    },
    {
        label: "POS",
        icon: "shopping-bag",
        items: [
            {
                label: TREEZ_PRODUCTS.label,
                route: TREEZ_PRODUCTS.route,
                // handler: TREEZ_PRODUCTS.handler,
            },
            {
                label: TREEZ_LIST_GROUPS.label,
                route: TREEZ_LIST_GROUPS.route,
                //handler: TREEZ_LIST_GROUPS.handler,
            },
            {
                label: TREEZ_LIST_DISCOUTS.label,
                route: TREEZ_LIST_DISCOUTS.route,
                //handler: TREEZ_LIST_DISCOUTS.handler,
            },
        ],
    },
];

export const toolBar = [
    {
        label: SETTINGS.label,
        route: SETTINGS.route,
        icon: "fas fa-cog",
    },
];
