import React, { useState } from "react";
import { Portlet, Section, SectionTitle, Table, Badge } from "@/components";
import { Button, Col, Row } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_STORE } from "../../apollo/module-operations/store";
import _ from "lodash";
import omit from "lodash/omit";
import {
    getDateRangeMessage,
    convertMilitaryTimeToAmPm,
    formatUpdateOrderInput,
    formatSetOrderInput,
    getColorReservationMode,
} from "@/utils";
import { useConfirm, useMeta, useNotification } from "@/hooks";
import ClosureForm from "./ClosureForm";
import SchedulesFormJSON from "./SchedulesFormJSON";

const Closures = ({ inputs, setInputs, loading, stores }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [showFormJson, setShowFormJson] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [mutate] = useMutation(MUTATION_UPDATE_STORE, {
        refetchQueries: [],
        onCompleted: resp => {
            let input = _.cloneDeep(resp.updateStore);

            setInputs(input);
            handleCancel();
            setRowSelect(null);
            setIsDelete(false);
            notify(
                `Closures Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  Closures, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        const newShedules = _.filter(
            inputs.closures,
            (i, index) => index !== rowSelect,
        );

        const updateInputs = {
            ...omit(
                {
                    ...inputs,
                    closures: newShedules,
                },
                "ignore_whitespace",
            ),
        };

        const { errors } = mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(updateInputs),
            },
        });

        if (errors) {
            setInputs(formatSetOrderInput(updateInputs));
        }
    }, `Are You Sure You Want To Remove The Closures?`);

    const columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.name || ""}`}</div>;
            },
        },
        {
            dataField: "reservation_mode",
            text: "Reservation Mode",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorReservationMode(row.reservation_mode) ||
                            "info"
                        }`}
                        label={row.reservation_mode}
                    />
                );
            },
        },
        {
            dataField: "start_date",
            text: "Closing Period",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div style={{ fontSize: "14px" }}>
                        {getDateRangeMessage(row.start_date, row.end_date)}
                        {row.all_day && (
                            <Badge className="status__error" label="All DAY" />
                        )}
                        {!row.all_day && (
                            <div>
                                <Badge
                                    className="status__error mb-1"
                                    label={`start: ${convertMilitaryTimeToAmPm(
                                        row.time_start,
                                    )}`}
                                />
                                <Badge
                                    className="status__error"
                                    label={`end: ${convertMilitaryTimeToAmPm(
                                        row.time_end,
                                    )}`}
                                />
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: "stores_provider_ids",
            text: "Stores Provider",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.stores_provider_ids || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex) => {
                setIsDelete(true);
                setIsCreate(false);
                setRowSelect(rowIndex);
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput({
                    ...omit(
                        {
                            ...inputs,
                            closures: array,
                        },
                        "ignore_whitespace",
                    ),
                }),
            },
        });
    };

    const handlerUpdatingOrCreateJSON = inputs => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(inputs),
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
        setShowFormJson(false);
    };

    const _closures = (inputs && inputs.closures) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "Closures",
                    subtitle: "Closures Information",
                }}
                sticky
                toolbar={[
                    <Button
                        className="ml-3"
                        color="secondary"
                        disabled={isUpdating || showFormJson || showForm}
                        key="addPickup"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Closure
                    </Button>,
                    <Button
                        className="ml-3"
                        color="primary"
                        disabled={isUpdating || showFormJson || showForm}
                        key="updateFromJSON"
                        onClick={() => {
                            setIsCreate(true);
                            setShowFormJson(true);
                        }}
                    >
                        <i className="fas fa-edit" />
                        Update Form JSON
                    </Button>,
                ]}
            >
                {showForm && (
                    <ClosureForm
                        closures={inputs.closures}
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        selectClosure={rowSelect}
                        stores={stores}
                    />
                )}
                {showFormJson && (
                    <SchedulesFormJSON
                        context="Closures"
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreateJSON}
                        inputs={inputs}
                        isUpdating={isUpdating}
                        setInputs={setInputs}
                    />
                )}
                <Section className="pb-4">
                    <Row>
                        <Col>
                            <SectionTitle md>Closures</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                actions={actions}
                                columns={columns}
                                data={_closures}
                                //expandRow={expandRow}
                                keyField="name"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Section>
            </Portlet>
            {confirm}
        </>
    );
};

export default Closures;
