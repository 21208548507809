import React, { useState, useEffect } from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    FormGroup,
    Label,
    Button,
    Row,
} from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import { omit } from "../../utils/utils";
import { useMutation, useQuery } from "@apollo/react-hooks";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useNotification from "../../base/hooks/components/useNotification";
import { roleOptions } from "../../config/constant";
import {
    QUERY_USER_LIST,
    MUTATION_CREATE_USER,
    MUTATION_UPDATE_USER,
    QUERY_USER_GET,
} from "../../apollo/module-operations/user";
import { QUERY_STORES_FROM_ACCOUNT_LIST } from "../../apollo/module-operations/store";

import Select from "../../base/components/form/select/Select";
import _ from "lodash";

const UserFormModal = ({ isOpen, toggle, userId, storeId }) => {
    const { user } = useMeta();
    const notify = useNotification();
    const [isFormReset, resetFormInputs] = useState(userId);
    const [storeOptions, setStoreOptions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [storesSelect, setStoresSelect] = useState([]);

    const {
        inputs,
        setInputs,
        inputChange,
        selectValue,
        selectChange,
        genericChange,
    } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "first_name",
                method: "isEmpty",
                validWhen: false,
                message: "This first name is required.",
            },
            {
                field: "last_name",
                method: "isEmpty",
                validWhen: false,
                message: "This last name is required.",
            },
            {
                field: "email",
                method: "isEmpty",
                validWhen: false,
                message: "This email is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (user) {
            let roles = roleOptions;
            if (!user?.roles?.find(r => r === "SUPER_ADMIN")) {
                roles = roleOptions.filter(r => r.value !== "SUPER_ADMIN");
            }
            setRoles(roles);
        }
    }, [user]);

    if (!userId && !isFormReset) {
        resetFormInputs(true);
        setInputs({ enabled: true });
    }

    let options = {
        variables: {
            input: {
                ...inputs,
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    };

    const { loading: userLoading } = useQuery(QUERY_USER_GET, {
        variables: {
            user_id: userId,
        },
        skip: !userId,
        fetchPolicy: "network-only",
        onCompleted: resp => {
            setInputs(resp.getUser);
        },
    });

    useQuery(QUERY_STORES_FROM_ACCOUNT_LIST, {
        variables: {
            account_id: user.accountId,
        },
        skip: user.loading,
        onCompleted: resp => {
            let ops = _.map(resp.listStoresFromAccount, v => {
                return { value: v.id, label: v.name };
            });

            setStoreOptions(ops);
        },
    });

    const [mutate, { loading }] = useMutation(
        userId !== null ? MUTATION_UPDATE_USER : MUTATION_CREATE_USER,
        {
            refetchQueries: [
                {
                    query: QUERY_USER_LIST,
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                    },
                },
            ],
            onCompleted: resp => {
                setStoresSelect([]);
                resetFormInputs(false);
                toggle(false);

                notify(
                    `User ${
                        userId !== null ? "Updated" : "Created"
                    } Successfully"`,
                    "success",
                );
            },
            onError: () => {
                notify(
                    `Error ${
                        userId !== null ? "Updating" : "Creating"
                    }  The Store`,
                    "error",
                );
            },
        },
    );

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            options = omit(options);
            mutate(options);
        }
    };

    const cancel = () => {
        setStoresSelect([]);
        resetFormInputs(false);
        toggle(false);
    };

    const onChangeStores = options => {
        let all = [];
        setStoresSelect(options);

        _.forEach(options, v => {
            all.push(v.value);
        });

        genericChange(all, "store_id");
    };

    const colorStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                ":active": {
                    backgroundColor: "#dbf5b3",
                },
                backgroundColor: isFocused ? "#EEFCE8" : null,
                color: "#1F2433",
            };
        },
    };

    return (
        <Modal isOpen={isOpen} onClosed={() => {}} size="lg">
            <ModalHeader>{`${userId ? "Update" : "Create"} User`}</ModalHeader>
            <ModalBody>
                {!userLoading ? (
                    <>
                        <Row>
                            <Col sm={6}>
                                <FormGroup className="mb-2">
                                    <Label>Active</Label>
                                    <br />
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.enabled
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="enabled"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup className="mb-2">
                                    <Label>First Name</Label>
                                    <Input
                                        invalid={validator.first_name.isInvalid}
                                        name="first_name"
                                        onChange={inputChange}
                                        placeholder="First Name"
                                        value={inputs.first_name}
                                    />
                                    <FormFeedback>
                                        {validator.first_name.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className="mb-2">
                                    <Label>Last Name</Label>
                                    <Input
                                        invalid={validator.last_name.isInvalid}
                                        name="last_name"
                                        onChange={inputChange}
                                        placeholder="Last Name"
                                        value={inputs.last_name}
                                    />
                                    <FormFeedback>
                                        {validator.last_name.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup className="mb-2">
                                    <Label>Email</Label>
                                    <Input
                                        invalid={validator.email.isInvalid}
                                        name="email"
                                        onChange={inputChange}
                                        placeholder="Email"
                                        value={inputs.email}
                                    />
                                    <FormFeedback>
                                        {validator.email.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className="mb-2">
                                    <Label>Role</Label>
                                    <Select
                                        controlName="role"
                                        onChange={selectChange}
                                        options={roles}
                                        value={selectValue(inputs.role, roles)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {!storeId && (
                            <Row>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label>Stores</Label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            controlName="store_id"
                                            creatable={true}
                                            isClearable={true}
                                            isMulti
                                            isSearchable={true}
                                            onChange={onChangeStores}
                                            options={storeOptions}
                                            placeholder="Store"
                                            styles={colorStyles}
                                            value={storesSelect}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                    </>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    disabled={false}
                    onClick={() => {
                        createOrUpdate();
                    }}
                >
                    <Spinner show={loading} sm />
                    {`${userId ? "Update" : "Create"} `}
                </Button>
                <Button
                    className="ml-3"
                    color="secondary"
                    disabled={loading}
                    onClick={cancel}
                    outline
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserFormModal;
