import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";

const GoogleForm = ({ toolbar, inputs, inputChange, loading }) => {
    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Google",
                subtitle: "Google GTM Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col
                                className="d-flex align-items-center"
                                lg={3}
                                md={6}
                            >
                                <FormGroup
                                    className="align-items-bottom mb-0"
                                    row
                                    style={{ gap: "8px" }}
                                >
                                    <Label className="row-label pl-0 m-0">
                                        GTM Active
                                    </Label>
                                    <Col>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label className="mb-0">
                                                <Input
                                                    checked={
                                                        inputs.gtm_active
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="gtm_active"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                {inputs.gtm_active && (
                                    <FormGroup className="mb-0" row>
                                        <Col>
                                            <Label>GTM ID</Label>
                                            <Input
                                                defaultValue={
                                                    inputs.gtm_id
                                                        ? inputs.gtm_id
                                                        : ""
                                                }
                                                name="gtm_id"
                                                onChange={inputChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={7}>
                                <Label>Google Map Api URL</Label>
                                <Input
                                    defaultValue={
                                        inputs.google_map_api_url ?? ""
                                    }
                                    name="google_map_api_url"
                                    onChange={inputChange}
                                    value={inputs.google_map_api_url ?? ""}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default GoogleForm;
