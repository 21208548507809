import React, { useState, useEffect } from "react";
import {
    Badge,
    Portlet,
    Section,
    SectionTitle,
    Table,
    Input,
    Spinner,
    Select,
} from "@/components";
import {
    Col,
    Collapse,
    Row,
    FormFeedback,
    FormGroup,
    Label,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_LISTTRACK } from "../../apollo/module-operations/app";
import { MUTATION_SYNC_JANE_PRODUCT_TO_LISTRACK } from "../../apollo/module-operations/jane";
import _ from "lodash";
import omit from "lodash/omit";
import {
    useConfirm,
    useForm,
    useMeta,
    useNotification,
    useValidator,
} from "@/hooks";
import {
    EVENT_TYPE_LISTTRACK,
    SUBSCRIPTION_SOURCE_TYPE,
} from "config/constant";
import ListTrackAddItem from "./ListTrackAddItem";
import classNames from "classnames";

const initialState = {
    form: "",
    idDelete: null,
    selected: null,
    updateList: [],
};

const ListTrackForm = ({ app, stores, loading }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [state, setState] = useState(initialState);
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState({
        order_fields: true,
        password_fields: false,
        store_fields: false,
        messages: false,
        subscribe_lists: false,
        subscribe_fields: false,
        sources: false,
    });

    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "client_app_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Client id is required.",
            },
            {
                field: "client_app_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Client secret is required.",
            },
            {
                field: "data_client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Data client id is required.",
            },
            {
                field: "data_client_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Data client secret is required.",
            },
            {
                field: "list_id",
                method: "isEmpty",
                validWhen: false,
                message: "This List id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_LISTTRACK, {
        refetchQueries: [],
        onCompleted: () => {
            setInputs({
                ...inputs,
                order_fields:
                    state.form === "Order Field"
                        ? state.updateList
                        : inputs.order_fields,
                password_fields:
                    state.form === "Password Field"
                        ? state.updateList
                        : inputs.password_fields,
                store_fields:
                    state.form === "Store Field"
                        ? state.updateList
                        : inputs.store_fields,
                messages:
                    state.form === "Message"
                        ? state.updateList
                        : inputs.messages,
                subscribe_lists:
                    state.form === "Subscribe Lists"
                        ? state.updateList
                        : inputs.subscribe_lists,
                subscribe_fields:
                    state.form === "Subscribe Fields"
                        ? state.updateList
                        : inputs.subscribe_fields,
                sources:
                    state.form === "Sources"
                        ? state.updateList
                        : inputs.sources,
            });
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setIsLoading(false);
            setState(initialState);
            notify(
                `${state.form} Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The ${
                    state.form
                }, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const handleDelete = list => {
        let newList = _.filter(list, item =>
            state.form === "Message"
                ? item.message_id !== state.idDelete
                : item.id !== state.idDelete,
        );
        setState({ ...state, updateList: newList });
        return newList;
    };

    const [confirm, launch] = useConfirm(() => {
        let _inputs = {
            ...inputs,
            client_id: inputs.client_app_id,
            client_secret: inputs.client_app_secret,
            order_fields:
                state.form === "Order Field"
                    ? handleDelete(inputs.order_fields)
                    : inputs.order_fields,
            password_fields:
                state.form === "Password Field"
                    ? handleDelete(inputs.password_fields)
                    : inputs.password_fields,
            store_fields:
                state.form === "Store Field"
                    ? handleDelete(inputs.store_fields)
                    : inputs.store_fields,
            messages:
                state.form === "Message"
                    ? handleDelete(inputs.messages)
                    : inputs.messages,
            subscribe_lists:
                state.form === "Subscribe Lists"
                    ? handleDelete(inputs.subscribe_lists)
                    : inputs.subscribe_lists,
            subscribe_fields:
                state.form === "Subscribe Fields"
                    ? handleDelete(inputs.subscribe_fields)
                    : inputs.subscribe_fields,
            sources:
                state.form === "Sources"
                    ? handleDelete(inputs.sources)
                    : inputs.sources,
        };

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...omit(_inputs, ["client_app_id", "client_app_secret"]),
                },
            },
        });
    }, `Are you sure you want to remove the item from ${state.form} ?`);

    //order fields, password_fields, store_fields
    const columnsDefault = [
        {
            dataField: "id",
            text: "Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.id || ""}`}</div>;
            },
        },
        {
            dataField: "name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.name || ""}`}</div>;
            },
        },
    ];

    //messages
    const columnsMessages = [
        {
            dataField: "message_id",
            text: "Message Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.message_id || ""}`}</div>;
            },
        },
        {
            dataField: "event_type",
            text: "Event Type",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>
                        {
                            EVENT_TYPE_LISTTRACK?.find(
                                e => e.value === row?.event_type,
                            )?.label
                        }
                    </div>
                );
            },
        },
    ];

    //sources
    const columnsSources = [
        {
            dataField: "id",
            text: "Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.id || ""}`}</div>;
            },
        },
        {
            dataField: "type",
            text: "type",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>
                        {
                            SUBSCRIPTION_SOURCE_TYPE?.find(
                                e => e.value === row?.type,
                            )?.label
                        }
                    </div>
                );
            },
        },
        {
            dataField: "event_id",
            text: "Event Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.event_id || ""}`}</div>;
            },
        },
    ];

    const renderAction = context => {
        return [
            {
                label: "Update",
                action: async (cell, row, rowIndex, formatExtraData) => {
                    setIsCreate(false);
                    setState({ ...state, form: context, selected: rowIndex });
                    setShowForm(true);
                    window.scrollTo(0, 0);
                },
            },
            {
                label: "Delete",
                action: async (cell, row, rowIndex, formatExtraData) => {
                    let id = context === "Message" ? row.message_id : row.id;
                    setState({ ...state, form: context, idDelete: id });
                    setIsDelete(true);
                    setIsCreate(false);
                    launch();
                },
            },
        ];
    };

    const renderFormCreate = form => {
        setState({
            ...state,
            form: form,
        });
        setIsCreate(true);
        setShowForm(true);
        window.scrollTo(0, 0);
    };

    const handlerUpdatingOrCreate = newArray => {
        let _inputs = {
            ...inputs,
            client_id: inputs.client_app_id,
            client_secret: inputs.client_app_secret,
            order_fields:
                state.form === "Order Field" ? newArray : inputs.order_fields,
            password_fields:
                state.form === "Password Field"
                    ? newArray
                    : inputs.password_fields,
            store_fields:
                state.form === "Store Field" ? newArray : inputs.store_fields,
            messages: state.form === "Message" ? newArray : inputs.messages,
            subscribe_lists:
                state.form === "Subscribe Lists"
                    ? newArray
                    : inputs.subscribe_lists,
            subscribe_fields:
                state.form === "Subscribe Fields"
                    ? newArray
                    : inputs.subscribe_fields,
            sources: state.form === "Sources" ? newArray : inputs.sources,
        };

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...omit(_inputs, ["client_app_id", "client_app_secret"]),
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const createOrUpdate = () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            setIsLoading(true);
            handlerUpdatingOrCreate();
        }
    };

    const toogle = (list, collapse) => {
        setIsCollapsed({
            ...isCollapsed,
            [list]: collapse,
        });
    };

    const [mutateSyncProductsFromTreez, { loading: syncLoading }] = useMutation(
        MUTATION_SYNC_JANE_PRODUCT_TO_LISTRACK,
        {
            onCompleted: resp => {
                notify("Sync Products From Jane Successfully", "success");
            },
            onError: () => {
                notify("Error Trying to Sync Product", "error");
            },
        },
    );

    const syncProducstFromJane = async id => {
        mutateSyncProductsFromTreez({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                jane_store_id: id,
            },
        });
    };

    const _orderFields = (inputs && inputs.order_fields) || [];
    const _passwordFields = (inputs && inputs.password_fields) || [];
    const _storeFields = (inputs && inputs.store_fields) || [];
    const _messages = (inputs && inputs.messages) || [];
    const _subscribeLists = (inputs && inputs.subscribe_lists) || [];
    const _subscribeFields = (inputs && inputs.subscribe_fields) || [];
    const _sourcesFields = (inputs && inputs.sources) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "ListTrak",
                    subtitle: "ListTrak Information",
                }}
                sticky
                toolbar={
                    <>
                        <UncontrolledDropdown className="ml-3 text-dark">
                            <DropdownToggle caret color="metal">
                                {syncLoading ? (
                                    <Spinner color="#0c2002" show={true} sm />
                                ) : (
                                    <i className={`fas fa-sync-alt`} />
                                )}
                                Sync Products
                            </DropdownToggle>
                            <DropdownMenu>
                                {stores.length ? (
                                    stores.map(item => {
                                        return (
                                            <DropdownItem
                                                key={item.value}
                                                onClick={() =>
                                                    syncProducstFromJane(
                                                        item.value,
                                                    )
                                                }
                                            >
                                                {item.label}
                                            </DropdownItem>
                                        );
                                    })
                                ) : (
                                    <span className="p-3">Not data ...</span>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown className="ml-3 text-dark">
                            <DropdownToggle caret color="metal">
                                <i className="fas fa-plus" /> Add Item
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Order Field")
                                    }
                                >
                                    Order Field
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Password Field")
                                    }
                                >
                                    Password Field
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Store Field")
                                    }
                                >
                                    Store Field
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => renderFormCreate("Message")}
                                >
                                    Message
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Subscribe Lists")
                                    }
                                >
                                    Subscribe Lists
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Subscribe Fields")
                                    }
                                >
                                    Subscribe Fields
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => renderFormCreate("Sources")}
                                >
                                    Sources
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button
                            className="ml-3"
                            color="primary"
                            disabled={isloading || showForm}
                            onClick={createOrUpdate}
                            style={{ minWidth: "128px" }}
                        >
                            <Spinner show={isloading} sm />
                            {!isloading && <i className="fas fa-save" />}
                            Update
                        </Button>
                    </>
                }
            >
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Information</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup row>
                            <Label className="row-label">
                                Used for subscription
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.used_for_subscription
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="used_for_subscription"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col md={4} xl={4}>
                            <FormGroup>
                                <Label>Client Id</Label>
                                <Input
                                    invalid={validator.client_app_id.isInvalid}
                                    name="client_app_id"
                                    onChange={inputChange}
                                    placeholder="Client Id"
                                    value={inputs.client_app_id}
                                />
                                <FormFeedback>
                                    {validator.client_app_id.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4} xl={4}>
                            <FormGroup>
                                <Label>Client Secret</Label>
                                <Input
                                    invalid={
                                        validator.client_app_secret.isInvalid
                                    }
                                    name="client_app_secret"
                                    onChange={inputChange}
                                    placeholder="Client Secret"
                                    value={inputs.client_app_secret}
                                />
                                <FormFeedback>
                                    {validator.client_app_secret.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} xl={4}>
                            <FormGroup>
                                <Label>Data Client Id</Label>
                                <Input
                                    invalid={validator.data_client_id.isInvalid}
                                    name="data_client_id"
                                    onChange={inputChange}
                                    placeholder="Data Client Id"
                                    value={inputs.data_client_id}
                                />
                                <FormFeedback>
                                    {validator.data_client_id.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4} xl={4}>
                            <FormGroup>
                                <Label>Data Client Secret</Label>
                                <Input
                                    invalid={
                                        validator.data_client_secret.isInvalid
                                    }
                                    name="data_client_secret"
                                    onChange={inputChange}
                                    placeholder="Data Client Secret"
                                    value={inputs.data_client_secret}
                                />
                                <FormFeedback>
                                    {validator.data_client_secret.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4} xl={4}>
                            <FormGroup>
                                <Label>List Id</Label>
                                <Input
                                    invalid={validator.list_id.isInvalid}
                                    name="list_id"
                                    onChange={inputChange}
                                    placeholder="List Id"
                                    value={inputs.list_id}
                                />
                                <FormFeedback>
                                    {validator.list_id.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </Section>
                {showForm && (
                    <ListTrackAddItem
                        app={inputs}
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        setState={setState}
                        state={state}
                    />
                )}
                <Section className="section-separator" />
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.order_fields,
                        })}
                        onClick={() =>
                            toogle("order_fields", !isCollapsed.order_fields)
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Order Fields</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.order_fields ? "up" : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.order_fields}>
                        <Col>
                            <Table
                                actions={renderAction("Order Field")}
                                columns={columnsDefault}
                                data={_orderFields}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.password_fields,
                        })}
                        onClick={() =>
                            toogle(
                                "password_fields",
                                !isCollapsed.password_fields,
                            )
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Password Fields</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.password_fields
                                            ? "up"
                                            : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.password_fields}>
                        <Col>
                            <Table
                                actions={renderAction("Password Field")}
                                columns={columnsDefault}
                                data={_passwordFields}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.store_fields,
                        })}
                        onClick={() =>
                            toogle("store_fields", !isCollapsed.store_fields)
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Store Fields</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.store_fields ? "up" : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.store_fields}>
                        <Col>
                            <Table
                                actions={renderAction("Store Field")}
                                columns={columnsDefault}
                                data={_storeFields}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.messages,
                        })}
                        onClick={() =>
                            toogle("messages", !isCollapsed.messages)
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Messages</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.messages ? "up" : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.messages}>
                        <Col>
                            <Table
                                actions={renderAction("Message")}
                                columns={columnsMessages}
                                data={_messages}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.subscribe_lists,
                        })}
                        onClick={() =>
                            toogle(
                                "subscribe_lists",
                                !isCollapsed.subscribe_lists,
                            )
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Subscribe Lists</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.subscribe_lists
                                            ? "up"
                                            : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.subscribe_lists}>
                        <Col>
                            <Table
                                actions={renderAction("Subscribe Lists")}
                                columns={columnsDefault}
                                data={_subscribeLists}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.subscribe_fields,
                        })}
                        onClick={() =>
                            toogle(
                                "subscribe_fields",
                                !isCollapsed.subscribe_fields,
                            )
                        }
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Subscribe Fields</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.subscribe_fields
                                            ? "up"
                                            : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.subscribe_fields}>
                        <Col>
                            <Table
                                actions={renderAction("Subscribe Fields")}
                                columns={columnsDefault}
                                data={_subscribeFields}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
                <Section mb-5>
                    <Row
                        className={classNames("list-collapse", {
                            "list-expanded": isCollapsed.sources,
                        })}
                        onClick={() => toogle("sources", !isCollapsed.sources)}
                    >
                        <Col>
                            <SectionTitle md>
                                <span>Sources</span>
                                <i
                                    className={`fas fa-caret-${
                                        isCollapsed.sources ? "up" : "down"
                                    } ml-3`}
                                />
                            </SectionTitle>
                        </Col>
                    </Row>
                    <Collapse isOpen={isCollapsed.sources}>
                        <Col>
                            <Table
                                actions={renderAction("Sources")}
                                columns={columnsSources}
                                data={_sourcesFields}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                        <Section className="section-separator" />
                    </Collapse>
                </Section>
            </Portlet>
            {confirm}
        </>
    );
};

export default ListTrackForm;
