import React, { useEffect, useState } from "react";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    Col,
    Row,
    FormFeedback,
    FormGroup,
    Label,
    Button,
    FormText,
} from "reactstrap";
import { useForm, useValidator } from "@/hooks";

import {
    Form,
    Icon,
    Input,
    InputGroupText,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";
import moment from "moment";
import { RESERVATION_MODE_OPTIONS } from "config/constant";

const ClosureForm = ({
    isCreate,
    handleCancel,
    closures,
    selectClosure,
    isUpdating,
    handlerUpdatingOrCreate,
    stores,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const [focusedInput, setFocusedInput] = useState(null);
    const { inputs, selectChange, selectValue, inputChange, setInputs } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "The Name is required.",
            },
            {
                field: "note",
                method: "isEmpty",
                validWhen: false,
                message: "The Note is required.",
            },
            {
                field: "reservation_mode",
                method: "isEmpty",
                validWhen: false,
                message: "The Reservation mode is required.",
            },
            {
                field: "stores_provider_ids",
                method: "isEmpty",
                validWhen: false,
                message: "The Store provider id is required.",
            },
            {
                field: "start_date",
                method: "isEmpty",
                validWhen: false,
                message: "The Start date is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (isCreate) {
            setInputs({
                ...inputs,
                all_day: true,
            });
        }

        if (!isCreate) {
            const objSelect = closures?.find(
                (_, index) => index === selectClosure,
            );

            setInputs({
                name: objSelect?.name ?? "",
                note: objSelect?.note ?? "",
                stores_provider_ids: objSelect?.stores_provider_ids,
                all_day: objSelect?.all_day ?? false,
                start_date: objSelect?.start_date,
                end_date: objSelect?.end_date,
                time_start: objSelect?.time_start,
                time_end: objSelect?.time_end,
                reservation_mode: objSelect?.reservation_mode,
            });
        }
    }, [closures]);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let newClosures = {
                name: inputs.name,
                note: inputs.note,
                stores_provider_ids: inputs.stores_provider_ids,
                all_day: inputs.all_day ?? false,
                start_date: inputs.start_date,
                end_date: inputs.end_date,
                time_start: inputs.time_start,
                time_end: inputs.time_end,
                reservation_mode: inputs.reservation_mode,
            };

            let updateSchedules = _.cloneDeep(closures ?? []);

            if (isCreate) {
                updateSchedules.push(newClosures);
            } else {
                updateSchedules[selectClosure] = newClosures;
            }

            handlerUpdatingOrCreate(updateSchedules);
        }
    };

    // On change store logic
    const onChangeStore = options => {
        const optionsSelect = options?.map(op => op.value);
        setInputs({ ...inputs, stores_provider_ids: optionsSelect });
    };

    const getRenderValues = idsList => {
        return stores?.filter(op =>
            idsList?.some(id => op.value?.includes(id)),
        );
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    const handleDatesChange = picker => {
        setInputs({
            ...inputs,
            start_date: picker.startDate.format("MM/DD/YYYY") || null,
            end_date: picker.endDate.format("MM/DD/YYYY") || null,
        });
    };

    const handleTimeChange = (field, value) => {
        setInputs({ ...inputs, [field]: value });
    };

    const handleAllDayChange = () => {
        const allDay = !inputs.all_day;

        if (allDay) {
            setInputs({
                ...inputs,
                all_day: allDay,
                time_end: null,
                time_start: null,
            });

            return;
        }

        setInputs({
            ...inputs,
            all_day: allDay,
        });
    };

    return (
        <Form className="mb-4">
            <Section className="p-4 mb-2">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Closure" : "Update Closure"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Informations</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input
                                invalid={validator.name.isInvalid}
                                name="name"
                                onChange={inputChange}
                                placeholder="Name"
                                value={inputs.name}
                            />
                            <FormFeedback>
                                {validator.name.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Reservation Mode</Label>
                            <Select
                                controlName="reservation_mode"
                                invalid={validator.reservation_mode.isInvalid}
                                onChange={selectChange}
                                options={RESERVATION_MODE_OPTIONS}
                                value={selectValue(
                                    inputs.reservation_mode,
                                    RESERVATION_MODE_OPTIONS,
                                )}
                            />
                            <FormFeedback>
                                {validator.reservation_mode.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>

                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Provider Id</Label>
                            <Select
                                closeMenuOnSelect={false}
                                controlName="stores_provider_ids"
                                invalid={
                                    validator.stores_provider_ids.isInvalid
                                }
                                isClearable={false}
                                isMulti
                                isSearchable={true}
                                onChange={onChangeStore}
                                options={stores}
                                value={getRenderValues(
                                    inputs.stores_provider_ids,
                                )}
                            />
                            <FormFeedback>
                                {validator.stores_provider_ids.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                        <FormGroup>
                            <Label>Note</Label>
                            <Input
                                name="note"
                                onChange={inputChange}
                                placeholder="Write the notes..."
                                rows={5}
                                type="textarea"
                                value={inputs.note}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SectionTitle md>Closing period</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <Label>Date</Label>
                        <FormGroup>
                            <DateRangePicker
                                displayFormat="MM/DD/YYYY"
                                endDate={inputs.end_date}
                                endDateId="end_date"
                                focusedInput={focusedInput}
                                isInvalidDate={date =>
                                    date.isBefore(moment(), "day")
                                }
                                key="multiDatePiker"
                                numberOfMonths={1}
                                onApply={(e, picker) => {
                                    handleDatesChange(picker);
                                }}
                                onFocusChange={input => setFocusedInput(input)}
                                singleDatePicker={false}
                                startDate={inputs.start_date ?? moment()}
                                startDateId="start_date"
                            >
                                <Input
                                    controlName="start_date"
                                    invalid={validator.start_date?.isInvalid}
                                    placeholder="Selected Range"
                                    prepend={
                                        <InputGroupText>
                                            <i className="fas fa-calendar-alt" />
                                        </InputGroupText>
                                    }
                                    readOnly
                                    value={
                                        inputs.start_date && inputs.end_date
                                            ? `${moment(
                                                  inputs.start_date,
                                              ).format(
                                                  "MM/DD/YYYY",
                                              )} - ${moment(
                                                  inputs.end_date,
                                              ).format("MM/DD/YYYY")}`
                                            : ""
                                    }
                                />
                                <FormFeedback>
                                    {validator.start_date?.message}
                                </FormFeedback>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center" lg={3} md={6}>
                        <FormGroup
                            className="align-items-bottom mb-0"
                            row
                            style={{ gap: "8px" }}
                        >
                            <Label className="row-label pl-0 m-0">
                                All Day
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label className="mb-0">
                                        <Input
                                            checked={
                                                inputs.all_day ? "checked" : ""
                                            }
                                            name="all_day"
                                            onChange={handleAllDayChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ height: "128px" }}>
                        {!inputs.all_day && (
                            <FormGroup className="mb-2">
                                <Label>Time</Label>
                                <Row>
                                    <Col
                                        className="ml-0 pl-0 pr-0"
                                        lg={3}
                                        md={4}
                                    >
                                        <FormGroup className="mb-0">
                                            <Input
                                                className="no-border-right"
                                                max="24:00"
                                                min="00:00"
                                                name="time_start"
                                                onChange={e =>
                                                    handleTimeChange(
                                                        "time_start",
                                                        e.target.value,
                                                    )
                                                }
                                                placeholder="Start time"
                                                type="time"
                                                value={inputs.time_start}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <div className="working__hours-decorator">
                                        <Icon icon={"arrow_next"} />
                                    </div>
                                    <Col className="pl-0" lg={3} md={4}>
                                        <FormGroup className="mb-0">
                                            <Input
                                                className="no-border-left"
                                                max="24:00"
                                                min={inputs.time_start}
                                                name="time_end"
                                                onChange={e =>
                                                    handleTimeChange(
                                                        "time_end",
                                                        e.target.value,
                                                    )
                                                }
                                                placeholder="End time"
                                                type="time"
                                                value={inputs.time_end}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormText className="mt-3">
                                    <b>Time start: </b>It is the time of day
                                    when closing begins.
                                    <br />
                                    <b>Time end: </b>It is the time of day when
                                    the closure ends.
                                </FormText>
                            </FormGroup>
                        )}
                    </Col>
                </Row>
            </Section>
            <Section className="p-4 mb-2">
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
            <Section className="section-separator" />
        </Form>
    );
};

export default ClosureForm;
