import gql from "graphql-tag";
let Order = {};
let OrderReduced = {};

OrderReduced.fragments = {
    attributes: gql`
        fragment OrderReducedFragment on OrderCursorPagination {
            cursor
            orders {
                entity_id
                key
                status
                client_detail {
                    device
                    user_agent
                    x_forwarded_for
                }
                email
                date_of_birth
                created_at
                updated_at
                delivery_address {
                    address1
                    address2
                    city
                    country
                    first_name
                    last_name
                    phone
                    province
                    zip
                    province_code
                    country_code
                    city_code
                    latitude
                    longitude
                }
                discount_items {
                    id
                    discountType
                    discount_rate_type
                    discount_apply_type
                    blaze_id
                    promo_code
                    rate
                }
                line_items {
                    item_id
                    id
                    name
                    quantity
                    price
                    weight
                    length
                    height
                    width
                    product_width
                    product_height
                    weight_unit
                    length_unit
                    width_unit
                    height_unit
                    thumbnail
                    pos_id
                    variants {
                        id
                        name
                        price
                        type
                    }
                    brand
                    category
                }
                notes
                order_number
                invoice_date
                payment_details {
                    type
                    card_type
                    last_four
                    refunded
                    refund_id
                }
                buyer_accepts_marketing
                blaze_cart_id
                delivery_details {
                    delivery
                    pickup
                    deliver_after
                    deliver_before
                    on_fleet {
                        id
                        worker_id
                        worker_name
                        worker_image
                        status
                        tracking_url
                        complete_after
                        complete_before
                    }
                }
                subtotal_price
                total_discounts
                total_line_items_price
                total_price
                total_tax
                total_delivery
            }
        }
    `,
};

Order.fragments = {
    attributes: gql`
        fragment OrderFragment on Order {
            entity_id
            key
            status
            created_at
            updated_at
            client_detail {
                device
                user_agent
                x_forwarded_for
            }
            email
            date_of_birth

            provider_store_id
            provider_store_full_address
            provider_store_name
            provider_store_phone_number

            delivery_address {
                address1
                address2
                city
                country
                first_name
                last_name
                phone
                province
                zip
                province_code
                country_code
                city_code
                latitude
                longitude
            }
            discount_items {
                id
                discountType
                discount_rate_type
                discount_apply_type
                blaze_id
                promo_code
                rate
            }
            line_items {
                item_id
                id
                pos_id
                name
                barcodes
                quantity
                price
                sale_price
                price_type
                weight
                length
                height
                width
                product_width
                product_height
                weight_unit
                length_unit
                width_unit
                height_unit
                thumbnail
                variants {
                    id
                    name
                    price
                    type
                }
                brand
                category
            }
            notes
            order_number
            invoice_date
            payment_details {
                type
                card_type
                last_four
                refunded
                refund_id
                aeropay_detail {
                    uuid
                    transaction_id
                    amount
                    merchant_name
                    customer_name
                }
                stronghold_detail {
                    charge_id
                    order_id
                    paylink_id
                    status
                }
            }
            buyer_accepts_marketing
            blaze_cart_id
            jane_cart_id
            jane_order_id
            jane_store_id
            jane_store_name
            jane_store_full_address
            jane_store_phone_number
            treez_order_id
            treez_order_number
            dutchie_order_id
            delivery_details {
                delivery
                pickup
                deliver_after
                deliver_before
                started_at
                finished_at
                time_window
                on_fleet {
                    id
                    worker_id
                    worker_name
                    worker_image
                    status
                    tracking_url
                    complete_after
                    complete_before
                }
            }
            subtotal_price
            total_discounts
            total_line_items_price
            total_price
            total_tax
            total_delivery
            total_service_fee
            activity_logs {
                action
                user
                status
                timestamp
            }
        }
    `,
};

export const QUERY_GET_ORDER = gql`
    query getOrder(
        $account_id: String!
        $store_id: String!
        $entity_id: String!
    ) {
        getOrder(
            account_id: $account_id
            store_id: $store_id
            entity_id: $entity_id
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;

export const QUERY_LIST_STORE_ORDER = gql`
    query listStoreOrders(
        $account_id: String!
        $store_id: String!
        $cursor: String
        $query: String!
        $last: Int!
        $before: String
    ) {
        listStoreOrders(
            account_id: $account_id
            store_id: $store_id
            cursor: $cursor
            query: $query
            last: $last
            before: $before
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    entity_id
                    key
                    status
                    client_detail {
                        device
                        user_agent
                        x_forwarded_for
                    }
                    email
                    date_of_birth
                    created_at
                    updated_at
                    delivery_address {
                        address1
                        address2
                        city
                        country
                        first_name
                        last_name
                        phone
                        province
                        zip
                        province_code
                        country_code
                        city_code
                        latitude
                        longitude
                    }
                    discount_items {
                        id
                        discountType
                        discount_rate_type
                        discount_apply_type
                        blaze_id
                        promo_code
                        rate
                    }
                    line_items {
                        item_id
                        id
                        name
                        quantity
                        price
                        weight
                        length
                        height
                        width
                        product_width
                        product_height
                        weight_unit
                        length_unit
                        width_unit
                        height_unit
                        thumbnail
                        variants {
                            id
                            name
                            price
                            type
                        }
                        brand
                        category
                    }
                    notes
                    order_number
                    invoice_date
                    payment_details {
                        type
                        card_type
                        last_four
                        refunded
                        refund_id
                    }
                    buyer_accepts_marketing
                    blaze_cart_id
                    delivery_details {
                        delivery
                        pickup
                        deliver_after
                        deliver_before
                        on_fleet {
                            id
                            worker_id
                            worker_name
                            worker_image
                            status
                            tracking_url
                            complete_after
                            complete_before
                        }
                    }
                    subtotal_price
                    total_discounts
                    total_line_items_price
                    total_price
                    total_tax
                    total_delivery
                }
            }
        }
    }
`;

export const QUERY_CUSTOMER_ORDER_HISTORY = gql`
    query customerOrderHistory($cursor: String, $email: String) {
        customerOrderHistory(cursor: $cursor, email: $email) {
            ...OrderReducedFragment
        }
    }
    ${OrderReduced.fragments.attributes}
`;

export const MUTATION_UPDATE_ORDER = gql`
    mutation updateOrder(
        $account_id: String!
        $store_id: String!
        $input: OrderInput!
    ) {
        updateOrder(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;

export const MUTATION_REFUND_PAYMENT = gql`
    mutation refundPayment($input: RefundPaymentInput!) {
        refundPayment(input: $input) {
            status
            error
            refund_id
        }
    }
`;

export const MUTATION_UPDATE_ORDER_STATUS = gql`
    mutation updateOrderStatus(
        $account_id: String!
        $store_id: String!
        $input: UpdateOrderStatusInput!
    ) {
        updateOrderStatus(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;

export const MUTATION_ADD_AEROPAY_PAYMENT_DETAIL = gql`
    mutation addAeropayPaymentDetailToOrder(
        $account_id: String!
        $store_id: String!
        $input: AddAeroPayPaymentDetailInput!
    ) {
        addAeropayPaymentDetailToOrder(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;

export const QUERY_ORDER_DELIVERY_ETA = gql`
    query getOrderDeliveryETA(
        $accountId: String!
        $storeId: String!
        $entityId: String!
    ) {
        getOrderDeliveryETA(
            account_id: $accountId
            store_id: $storeId
            entity_id: $entityId
        )
    }
`;

export const MUTATION_UPDATE_ORDER_LINE_ITEMS_BARCODES = gql`
    mutation updateOrderLineItemsBarCodes(
        $account_id: String!
        $store_id: String!
        $input: UpdateOrderLineItemsBarCodesInput!
    ) {
        updateOrderLineItemsBarCodes(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;

export const MUTATION_CHECK_ORDER_LINE_ITEMS_BARCODES = gql`
    mutation checkOrderLineItemsBarCodes(
        $account_id: String!
        $store_id: String!
        $input: UpdateOrderLineItemsBarCodesInput!
    ) {
        checkOrderLineItemsBarCodes(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_UPDATE_ORDER_DETAIL = gql`
    mutation updateOrderDetails(
        $account_id: String!
        $store_id: String!
        $input: UpdateOrderDetailsInput!
    ) {
        updateOrderDetails(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...OrderFragment
        }
    }
    ${Order.fragments.attributes}
`;
